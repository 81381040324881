<template>
  <div class="component-props-setting">
    <div
      class="props-container"
      v-if="dataset.propsList && dataset.propsList.length"
    >
      <el-form
        ref="propsForm"
        :model="propsForm"
        :rules="formRules"
        label-width="120px"
        label-position="top"
      >
        <SinglePropsItem
          v-for="item in dataset.propsList"
          :key="item.key"
          :dataset="item"
          :itemType="dataset.type"
          :propsList="dataset.propsList"
          @update="updateHandler"
          @getItem="getItemHandler"
        />
      </el-form>
    </div>
    <div class="default-container" v-else>
      <div class="info-list">
        <div
          class="info-item"
          v-for="item in dataset.productInfo"
          :key="item.infoKey"
        >
          <div class="label-line">
            {{ item.label }}
          </div>
          <div class="value-line">
            {{ item.value }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SinglePropsItem from "./singlePropsItem.vue";
export default {
  name: "component-props-setting",
  components: {
    SinglePropsItem,
  },
  props: {
    // input | textarea
    dataset: {
      type: Object,
      default() {
        return {
          propsList: [],
        };
      },
    },
  },
  watch: {
    dataset: {
      handler(datas) {
        this.buildFormAndRules(datas.propsList);
      },
      deep: true,
    },
  },
  data() {
    return {
      propsForm: {},
      formRules: {
        // code: [{ required: true, message: "请输入规则名称", trigger: "blur" }],
      },
    };
  },
  methods: {
    getItemHandler() {
      return this.dataset;
    },
    async checkForm() {
      return await this.$refs.propsForm.validate();
    },
    updateHandler(key, prop, value) {
      // console.log("value", value);
      // this.dataset.propsList.forEach((item) => {
      //   if (item.key === key) {
      //     item[prop] = value;
      //   }
      // });
    },
    buildFormAndRules(propsList) {
      propsList.forEach((item) => {
        const { key, value, require } = item;
        this.$set(this.propsForm, key, value);
        if (require) {
          this.$set(this.formRules, key, [
            {
              required: true,
              message: "请输入",
              trigger: ["blur", "change"],
            },
          ]);
        }
        if (key === "default") {
          this.$set(this.formRules, key, [
            {
              required: false,
              trigger: ["blur", "change"],
            },
            {
              validator: (rule, value, callback) => {
                if (value && value.length > this.propsForm.maxLength) {
                  callback("长度超过最大字数");
                }
                callback();
              },
              trigger: ["blur", "change"],
            },
          ]);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.component-props-setting {
  width: 100%;
  height: 100%;
  overflow: auto;
  .default-container {
    padding: 20px;
    .info-list {
      .info-item {
        height: 36px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-bottom: 30px;
        .label-line {
          font-weight: 600;
          font-size: 14px;
          color: #252d3d;
        }
        .value-line {
          font-weight: 400;
          font-size: 14px;
          color: #5d687c;
        }
      }
    }
  }
  .props-container {
  }
}
</style>