var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.dataset.key !== "companyOptions"
    ? _c(
        "div",
        { staticClass: "single-props-item" },
        [
          _c(
            "el-form-item",
            {
              class: ["single-form-item", `single-form-${_vm.dataset.key}`],
              attrs: { label: _vm.dataset.label, prop: _vm.dataset.key },
            },
            [
              _vm.dataset.key === "maxLength"
                ? [
                    _c("el-input-number", {
                      staticStyle: { width: "100%" },
                      attrs: {
                        min: 1,
                        max: 1000,
                        label: _vm.dataset.placeHolder,
                      },
                      model: {
                        value: _vm.dataset.value,
                        callback: function ($$v) {
                          _vm.$set(_vm.dataset, "value", $$v)
                        },
                        expression: "dataset.value",
                      },
                    }),
                  ]
                : _vm.dataset.key === "layout"
                ? [
                    _c("div", { staticClass: "layout-container" }, [
                      _c(
                        "div",
                        {
                          class: [
                            "layout-item layout-half",
                            _vm.dataset.value === "half" && "layout-active",
                          ],
                          on: {
                            click: function ($event) {
                              _vm.dataset.value = "half"
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "inner-item" }),
                          _c("div", { staticClass: "inner-item" }),
                          _vm.dataset.value === "half"
                            ? _c("img", {
                                staticClass: "active-corner",
                                attrs: {
                                  src: require("@/assets/images/dynamicForm/layout-active-corner.png"),
                                  alt: "",
                                },
                              })
                            : _vm._e(),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          class: [
                            "layout-item layout-all",
                            _vm.dataset.value === "all" && "layout-active",
                          ],
                          on: {
                            click: function ($event) {
                              _vm.dataset.value = "all"
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "inner-item" }),
                          _vm.dataset.value === "all"
                            ? _c("img", {
                                staticClass: "active-corner",
                                attrs: {
                                  src: require("@/assets/images/dynamicForm/layout-active-corner.png"),
                                  alt: "",
                                },
                              })
                            : _vm._e(),
                        ]
                      ),
                    ]),
                  ]
                : _vm.dataset.key === "require"
                ? [
                    _c("el-switch", {
                      staticClass: "require-switch",
                      attrs: {
                        "active-color": "#2A61FF",
                        "inactive-color": "#ccc",
                        "active-value": true,
                        "inactive-value": false,
                      },
                      model: {
                        value: _vm.dataset.value,
                        callback: function ($$v) {
                          _vm.$set(_vm.dataset, "value", $$v)
                        },
                        expression: "dataset.value",
                      },
                    }),
                  ]
                : _vm.dataset.key === "multiple"
                ? [
                    _c("el-switch", {
                      staticClass: "require-switch",
                      attrs: {
                        "active-color": "#2A61FF",
                        "inactive-color": "#ccc",
                        "active-value": true,
                        "inactive-value": false,
                      },
                      model: {
                        value: _vm.dataset.value,
                        callback: function ($$v) {
                          _vm.$set(_vm.dataset, "value", $$v)
                        },
                        expression: "dataset.value",
                      },
                    }),
                  ]
                : _vm.dataset.key === "clearable"
                ? [
                    _c("el-switch", {
                      staticClass: "require-switch",
                      attrs: {
                        "active-color": "#2A61FF",
                        "inactive-color": "#ccc",
                        "active-value": true,
                        "inactive-value": false,
                      },
                      model: {
                        value: _vm.dataset.value,
                        callback: function ($$v) {
                          _vm.$set(_vm.dataset, "value", $$v)
                        },
                        expression: "dataset.value",
                      },
                    }),
                  ]
                : _vm.dataset.key === "filterable"
                ? [
                    _c("el-switch", {
                      staticClass: "require-switch",
                      attrs: {
                        "active-color": "#2A61FF",
                        "inactive-color": "#ccc",
                        "active-value": true,
                        "inactive-value": false,
                      },
                      model: {
                        value: _vm.dataset.value,
                        callback: function ($$v) {
                          _vm.$set(_vm.dataset, "value", $$v)
                        },
                        expression: "dataset.value",
                      },
                    }),
                  ]
                : _vm.dataset.key === "occupy"
                ? [
                    _c(
                      "div",
                      [
                        _c("el-switch", {
                          staticClass: "require-switch",
                          attrs: {
                            "active-color": "#2A61FF",
                            "inactive-color": "#ccc",
                            "active-value": true,
                            "inactive-value": false,
                          },
                          model: {
                            value: _vm.dataset.value.value,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataset.value, "value", $$v)
                            },
                            expression: "dataset.value.value",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm.dataset.value.value
                      ? [
                          _c(
                            "div",
                            { staticClass: "occupy-line" },
                            [
                              _c("span", { staticClass: "occupy-label" }, [
                                _vm._v(" 显示清除按钮 "),
                              ]),
                              _c("el-switch", {
                                attrs: {
                                  "active-color": "#2A61FF",
                                  "inactive-color": "#ccc",
                                  "active-value": true,
                                  "inactive-value": false,
                                },
                                model: {
                                  value: _vm.dataset.value.clearable,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.dataset.value,
                                      "clearable",
                                      $$v
                                    )
                                  },
                                  expression: "dataset.value.clearable",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            [
                              _c("div", { staticClass: "occupy-label" }, [
                                _vm._v("占位内容"),
                              ]),
                              _c("el-input", {
                                model: {
                                  value: _vm.dataset.value.placeHolder,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.dataset.value,
                                      "placeHolder",
                                      $$v
                                    )
                                  },
                                  expression: "dataset.value.placeHolder",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      : _vm._e(),
                  ]
                : _vm.dataset.key === "options"
                ? [
                    _c("div", { staticClass: "option-container" }, [
                      _c("div", { staticClass: "option-tabs" }, [
                        _c(
                          "span",
                          {
                            class: [
                              "single-options",
                              _vm.dataset.config.type === "static" &&
                                `single-option-active`,
                            ],
                            on: {
                              click: function ($event) {
                                return _vm.optionsChange("static")
                              },
                            },
                          },
                          [_vm._v("静态数据")]
                        ),
                        _c(
                          "span",
                          {
                            class: [
                              "single-options",
                              _vm.dataset.config.type === "inner" &&
                                `single-option-active`,
                            ],
                            on: {
                              click: function ($event) {
                                return _vm.optionsChange("inner")
                              },
                            },
                          },
                          [_vm._v("内部服务")]
                        ),
                        _c(
                          "span",
                          {
                            class: [
                              "single-options",
                              _vm.dataset.config.type === "classify" &&
                                `single-option-active`,
                            ],
                            on: {
                              click: function ($event) {
                                return _vm.optionsChange("classify")
                              },
                            },
                          },
                          [_vm._v("数据字典")]
                        ),
                      ]),
                      _vm.dataset.config.type === "static"
                        ? _c("div", { staticClass: "static-config" }, [
                            _c("div", { staticClass: "tools-line" }, [
                              _c("div", [
                                _c("i", {
                                  staticClass: "el-icon-plus ope-icon",
                                  on: { click: _vm.addOption },
                                }),
                              ]),
                              _c("div", [
                                _c("i", {
                                  staticClass: "el-icon-top ope-icon",
                                  on: { click: _vm.moveElementForward },
                                }),
                                _c("i", {
                                  staticClass: "el-icon-bottom ope-icon",
                                  on: { click: _vm.moveElementBackward },
                                }),
                                _c("i", {
                                  staticClass: "el-icon-delete ope-icon",
                                  on: { click: _vm.deleteOption },
                                }),
                              ]),
                            ]),
                            _c(
                              "div",
                              { staticClass: "list-container" },
                              _vm._l(
                                _vm.dataset.config.optionList,
                                function (item, index) {
                                  return _c(
                                    "div",
                                    { key: index, staticClass: "static-line" },
                                    [
                                      _c(
                                        "span",
                                        {
                                          class: [
                                            "selected-span",
                                            item.selected &&
                                              "selected-span-active",
                                          ],
                                          on: {
                                            click: function ($event) {
                                              return _vm.optionActiveChange(
                                                index
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("span", {
                                            class: [
                                              "inner",
                                              item.selected && "inner-active",
                                            ],
                                          }),
                                        ]
                                      ),
                                      _c("el-input", {
                                        staticClass: "value-input",
                                        attrs: {
                                          placeholder: "值",
                                          size: "mini",
                                        },
                                        model: {
                                          value: item.value,
                                          callback: function ($$v) {
                                            _vm.$set(item, "value", $$v)
                                          },
                                          expression: "item.value",
                                        },
                                      }),
                                      _c("el-input", {
                                        staticClass: "label-input",
                                        attrs: {
                                          placeholder: "显示内容",
                                          size: "mini",
                                        },
                                        model: {
                                          value: item.label,
                                          callback: function ($$v) {
                                            _vm.$set(item, "label", $$v)
                                          },
                                          expression: "item.label",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                }
                              ),
                              0
                            ),
                          ])
                        : _vm.dataset.config.type === "inner"
                        ? _c("div", { staticClass: "inner-config" }, [
                            _c(
                              "div",
                              { staticClass: "single-inner" },
                              [
                                _c("span", { staticClass: "inner-label" }, [
                                  _vm._v(" 服务地址 "),
                                ]),
                                _c("el-input", {
                                  staticClass: "inner-input",
                                  attrs: { placeholder: "请输入" },
                                  model: {
                                    value: _vm.dataset.config.serviceUrl,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.dataset.config,
                                        "serviceUrl",
                                        $$v
                                      )
                                    },
                                    expression: "dataset.config.serviceUrl",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "single-inner" },
                              [
                                _c("span", { staticClass: "inner-label" }, [
                                  _vm._v(" 显示内容 "),
                                ]),
                                _c("el-input", {
                                  staticClass: "inner-input",
                                  attrs: { placeholder: "请输入" },
                                  model: {
                                    value: _vm.dataset.config.labelKey,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.dataset.config,
                                        "labelKey",
                                        $$v
                                      )
                                    },
                                    expression: "dataset.config.labelKey",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "single-inner" },
                              [
                                _c("span", { staticClass: "inner-label" }, [
                                  _vm._v(" 值 "),
                                ]),
                                _c("el-input", {
                                  staticClass: "inner-input",
                                  attrs: { placeholder: "请输入" },
                                  model: {
                                    value: _vm.dataset.config.valueKey,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.dataset.config,
                                        "valueKey",
                                        $$v
                                      )
                                    },
                                    expression: "dataset.config.valueKey",
                                  },
                                }),
                              ],
                              1
                            ),
                          ])
                        : _vm.dataset.config.type === "classify"
                        ? _c(
                            "div",
                            { staticClass: "classify-config" },
                            [
                              _c(
                                "el-select",
                                {
                                  ref: "classifyIns",
                                  staticClass: "inner-input",
                                  attrs: {
                                    placeholder: "类别编码",
                                    clearable: "",
                                    filterable: "",
                                    "filter-method": _vm.filterHandler,
                                  },
                                  on: { clear: _vm.filterHandler },
                                  model: {
                                    value: _vm.dataset.config.classifyCode,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.dataset.config,
                                        "classifyCode",
                                        $$v
                                      )
                                    },
                                    expression: "dataset.config.classifyCode",
                                  },
                                },
                                _vm._l(
                                  _vm.classifyOptionList,
                                  function (item, index) {
                                    return _c("el-option", {
                                      key: index,
                                      attrs: {
                                        value: item.value,
                                        label: item.label,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.dataset.config.type === "inner",
                              expression: "dataset.config.type === 'inner'",
                            },
                          ],
                          staticClass: "debugger-span",
                          on: { click: _vm.openDebugger },
                        },
                        [_vm._v("\n          调试\n        ")]
                      ),
                    ]),
                  ]
                : [
                    _c("el-input", {
                      attrs: {
                        placeholder: _vm.dataset.value || "请输入",
                        maxlength: _vm.dataset.key === "default" ? 999 : 20,
                        "show-word-limit": _vm.dataset.key !== "default",
                      },
                      on: { input: _vm.inputChange },
                      model: {
                        value: _vm.dataset.value,
                        callback: function ($$v) {
                          _vm.$set(_vm.dataset, "value", $$v)
                        },
                        expression: "dataset.value",
                      },
                    }),
                  ],
            ],
            2
          ),
          _c("DebuggerDialog", {
            attrs: { dataset: _vm.debuggerDatas },
            on: {
              close: function ($event) {
                _vm.debuggerDatas.show = false
              },
              apply: _vm.applyHandler,
            },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }