<template>
  <div class="single-props-item" v-if="dataset.key !== 'companyOptions'">
    <el-form-item
      :label="dataset.label"
      :prop="dataset.key"
      :class="['single-form-item', `single-form-${dataset.key}`]"
    >
      <!-- 最大长度 -->
      <template v-if="dataset.key === 'maxLength'">
        <el-input-number
          style="width: 100%"
          v-model="dataset.value"
          :min="1"
          :max="1000"
          :label="dataset.placeHolder"
        ></el-input-number>
      </template>
      <!-- 布局 -->
      <template v-else-if="dataset.key === 'layout'">
        <div class="layout-container">
          <div
            :class="[
              'layout-item layout-half',
              dataset.value === 'half' && 'layout-active',
            ]"
            @click="dataset.value = 'half'"
          >
            <div class="inner-item"></div>
            <div class="inner-item"></div>
            <img
              class="active-corner"
              src="@/assets/images/dynamicForm/layout-active-corner.png"
              v-if="dataset.value === 'half'"
              alt=""
            />
          </div>
          <div
            :class="[
              'layout-item layout-all',
              dataset.value === 'all' && 'layout-active',
            ]"
            @click="dataset.value = 'all'"
          >
            <div class="inner-item"></div>
            <img
              class="active-corner"
              src="@/assets/images/dynamicForm/layout-active-corner.png"
              v-if="dataset.value === 'all'"
              alt=""
            />
          </div>
        </div>
      </template>
      <!-- 必填 -->
      <template v-else-if="dataset.key === 'require'">
        <el-switch
          class="require-switch"
          v-model="dataset.value"
          active-color="#2A61FF"
          inactive-color="#ccc"
          :active-value="true"
          :inactive-value="false"
        >
        </el-switch>
      </template>
      <!-- 是否多选 -->
      <template v-else-if="dataset.key === 'multiple'">
        <el-switch
          class="require-switch"
          v-model="dataset.value"
          active-color="#2A61FF"
          inactive-color="#ccc"
          :active-value="true"
          :inactive-value="false"
        >
        </el-switch>
      </template>
      <!-- 显示清除按钮 -->
      <template v-else-if="dataset.key === 'clearable'">
        <el-switch
          class="require-switch"
          v-model="dataset.value"
          active-color="#2A61FF"
          inactive-color="#ccc"
          :active-value="true"
          :inactive-value="false"
        >
        </el-switch>
      </template>
      <!-- 是否可搜索 -->
      <template v-else-if="dataset.key === 'filterable'">
        <el-switch
          class="require-switch"
          v-model="dataset.value"
          active-color="#2A61FF"
          inactive-color="#ccc"
          :active-value="true"
          :inactive-value="false"
        >
        </el-switch>
      </template>
      <!-- 占位 -->
      <template v-else-if="dataset.key === 'occupy'">
        <div>
          <el-switch
            class="require-switch"
            v-model="dataset.value.value"
            active-color="#2A61FF"
            inactive-color="#ccc"
            :active-value="true"
            :inactive-value="false"
          >
          </el-switch>
        </div>
        <template v-if="dataset.value.value">
          <div class="occupy-line">
            <span class="occupy-label"> 显示清除按钮 </span>
            <el-switch
              v-model="dataset.value.clearable"
              active-color="#2A61FF"
              inactive-color="#ccc"
              :active-value="true"
              :inactive-value="false"
            >
            </el-switch>
          </div>
          <div>
            <div class="occupy-label">占位内容</div>
            <el-input v-model="dataset.value.placeHolder" />
          </div>
        </template>
      </template>
      <!-- 选项 -->
      <template v-else-if="dataset.key === 'options'">
        <div class="option-container">
          <div class="option-tabs">
            <span
              @click="optionsChange('static')"
              :class="[
                'single-options',
                dataset.config.type === 'static' && `single-option-active`,
              ]"
              >静态数据</span
            >
            <span
              @click="optionsChange('inner')"
              :class="[
                'single-options',
                dataset.config.type === 'inner' && `single-option-active`,
              ]"
              >内部服务</span
            >
            <span
              @click="optionsChange('classify')"
              :class="[
                'single-options',
                dataset.config.type === 'classify' && `single-option-active`,
              ]"
              >数据字典</span
            >
          </div>
          <div v-if="dataset.config.type === 'static'" class="static-config">
            <div class="tools-line">
              <div>
                <i class="el-icon-plus ope-icon" @click="addOption"></i>
              </div>
              <div>
                <i class="el-icon-top ope-icon" @click="moveElementForward"></i>
                <i
                  class="el-icon-bottom ope-icon"
                  @click="moveElementBackward"
                ></i>
                <i class="el-icon-delete ope-icon" @click="deleteOption"></i>
              </div>
            </div>
            <div class="list-container">
              <div
                class="static-line"
                v-for="(item, index) in dataset.config.optionList"
                :key="index"
              >
                <span
                  @click="optionActiveChange(index)"
                  :class="[
                    'selected-span',
                    item.selected && 'selected-span-active',
                  ]"
                >
                  <span
                    :class="['inner', item.selected && 'inner-active']"
                  ></span>
                </span>
                <el-input
                  v-model="item.value"
                  class="value-input"
                  placeholder="值"
                  size="mini"
                ></el-input>
                <el-input
                  v-model="item.label"
                  class="label-input"
                  placeholder="显示内容"
                  size="mini"
                ></el-input>
              </div>
            </div>
          </div>
          <div v-else-if="dataset.config.type === 'inner'" class="inner-config">
            <div class="single-inner">
              <span class="inner-label"> 服务地址 </span>
              <el-input
                class="inner-input"
                v-model="dataset.config.serviceUrl"
                placeholder="请输入"
              ></el-input>
            </div>
            <div class="single-inner">
              <span class="inner-label"> 显示内容 </span>
              <el-input
                class="inner-input"
                v-model="dataset.config.labelKey"
                placeholder="请输入"
              ></el-input>
            </div>
            <div class="single-inner">
              <span class="inner-label"> 值 </span>
              <el-input
                class="inner-input"
                v-model="dataset.config.valueKey"
                placeholder="请输入"
              ></el-input>
            </div>
          </div>
          <div
            v-else-if="dataset.config.type === 'classify'"
            class="classify-config"
          >
            <el-select
              class="inner-input"
              v-model="dataset.config.classifyCode"
              placeholder="类别编码"
              clearable
              ref="classifyIns"
              filterable
              @clear="filterHandler"
              :filter-method="filterHandler"
            >
              <el-option
                v-for="(item, index) in classifyOptionList"
                :value="item.value"
                :label="item.label"
                :key="index"
              ></el-option>
            </el-select>
          </div>
          <span
            v-show="dataset.config.type === 'inner'"
            class="debugger-span"
            @click="openDebugger"
          >
            调试
          </span>
        </div>
      </template>
      <template v-else>
        <!-- onkeyup="this.value = this.value.replace(/[^a-zA-Z0-9_-]/g, '')" -->
        <el-input
          v-model="dataset.value"
          :placeholder="dataset.value || '请输入'"
          @input="inputChange"
          :maxlength="dataset.key === 'default' ? 999 : 20"
          :show-word-limit="dataset.key !== 'default'"
        ></el-input>
      </template>
    </el-form-item>
    <DebuggerDialog
      :dataset="debuggerDatas"
      @close="debuggerDatas.show = false"
      @apply="applyHandler"
    />
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import DebuggerDialog from "./debuggerDialog.vue";
import request from "@/utils/request";
import { envInfo } from "@/constants/envInfo";

export default {
  name: "single-props-item",
  components: {
    DebuggerDialog,
  },
  props: {
    dataset: {
      type: Object,
      default() {
        return {};
      },
    },
    propsList: {
      type: Array,
      default() {
        return [];
      },
    },
    itemType: {
      type: String,
      default() {
        return "";
      },
    },
  },
  watch: {
    dataset: {
      handler() {
        this.initOptions();
      },
    },
  },
  created() {
    this.initOptions();
  },
  data() {
    return {
      classifyResource: [],
      classifyOptionList: [],
      debuggerDatas: {
        show: false,
      },
    };
  },
  methods: {
    filterHandler(value) {
      this.dataset.config.classifyCode = value;
      if (value) {
        this.classifyOptionList = cloneDeep(
          this.classifyResource.filter(
            (item) => item.value.includes(value) || item.label.includes(value)
          )
        );
      } else {
        this.classifyOptionList = cloneDeep(this.classifyResource);
      }
    },
    initOptions() {
      if (
        this.itemType === "selector" &&
        this.dataset.key === "options" &&
        this.dataset.config.type === "classify" &&
        this.classifyOptionList.length === 0
      ) {
        request({
          url: envInfo.bgApp.lookupPath + `/tenant/lookup/classify/all`,
          method: "get",
        }).then((res) => {
          const temp = res.map((item) => {
            return {
              value: item.classifyCode,
              label: item.classifyName,
            };
          });
          this.classifyResource = cloneDeep(temp);
          this.classifyOptionList = cloneDeep(temp);
        });
      }
    },
    optionsChange(type) {
      this.dataset.config.type = type;
      this.initOptions();
    },
    applyHandler(datas) {
      this.dataset.config = {
        ...this.dataset.config,
        ...datas,
      };
      this.debuggerDatas.show = false;
    },
    openDebugger() {
      const { serviceUrl, labelKey, valueKey } = this.dataset.config;
      this.debuggerDatas.datas = {
        serviceUrl,
        labelKey,
        valueKey,
        propsList: this.propsList,
      };
      this.debuggerDatas.show = true;
    },
    inputChange() {
      if (this.dataset.key !== "code") return;
      this.dataset.value = this.dataset.value.replace(/[^a-zA-Z0-9-_]/g, "");
    },
    optionActiveChange(index) {
      this.dataset.config.optionList.forEach((item, ind) => {
        item.selected = index === ind;
      });
    },
    addOption() {
      this.dataset.config.optionList.push({
        value: null,
        label: null,
        selected: false,
      });
    },
    deleteOption() {
      if (this.dataset.config.optionList.length === 1) {
        this.$message.warning("请至少保留一项");
        return;
      }
      this.dataset.config.optionList = this.dataset.config.optionList.filter(
        (item) => !item.selected
      );
      this.dataset.config.optionList[0].selected = true;
    },
    moveElementForward() {
      const index = this.dataset.config.optionList.findIndex(
        (item) => item.selected
      );
      if (index <= 0) {
        return;
      }
      // 因为是props中的值，所以只能单个更新每个属性，直接换位置不会触发更新
      const curList = this.dataset.config.optionList;
      const currentItem = cloneDeep(curList[index]);
      const prevItem = cloneDeep(curList[index - 1]);
      curList[index].value = prevItem.value;
      curList[index].label = prevItem.label;
      curList[index].selected = prevItem.selected;
      curList[index - 1].value = currentItem.value;
      curList[index - 1].label = currentItem.label;
      curList[index - 1].selected = currentItem.selected;
    },
    moveElementBackward() {
      const index = this.dataset.config.optionList.findIndex(
        (item) => item.selected
      );
      if (index >= this.dataset.config.optionList.length) {
        return;
      }
      // 因为是props中的值，所以只能单个更新每个属性，直接换位置不会触发更新
      const curList = this.dataset.config.optionList;
      const currentItem = cloneDeep(curList[index]);
      const afterItem = cloneDeep(curList[index + 1]);
      curList[index].value = afterItem.value;
      curList[index].label = afterItem.label;
      curList[index].selected = afterItem.selected;
      curList[index + 1].value = currentItem.value;
      curList[index + 1].label = currentItem.label;
      curList[index + 1].selected = currentItem.selected;
    },
  },
};
</script>

<style lang="less" scoped>
.single-props-item {
  padding: 0 20px;
  border-bottom: 1px solid #e3e8ee;
  .single-form-item {
    padding-bottom: 10px;
    margin-top: 14px;
    position: relative;
    .layout-container {
      display: flex;
      flex-direction: column;
      .layout-item {
        width: 240px;
        height: 58px;
        border-radius: 6px;
        border: 1px solid #e4e7eb;
        cursor: pointer;
        position: relative;
        .active-corner {
          position: absolute;
          right: 0;
          top: 0;
        }
      }
      .layout-active {
        border-color: #2a61ff;
      }
      .layout-half {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        .inner-item {
          width: 100px;
          height: 36px;
          background: #b5bece;
          border-radius: 4px;
        }
      }
      .layout-all {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        margin-top: 10px;
        .inner-item {
          width: 210px;
          height: 36px;
          background: #b5bece;
          border-radius: 4px;
        }
      }
    }
    .require-switch {
      position: absolute;
      right: 0;
      top: -28px;
    }
    .occupy-line {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 10px 0;
    }
    .occupy-label {
      font-weight: 400;
      font-size: 14px;
      color: #5d687c;
    }
    .option-container {
      position: relative;
      .option-tabs {
        height: 40px;
        width: 100%;
        border-radius: 10px;
        border: 1px solid #e9eff4;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        padding: 6px 4px;
        .single-options {
          flex: 1;
          cursor: pointer;
          height: 28px;
          line-height: 28px;
          text-align: center;
          color: #5d687c;
          font-weight: 400;
          font-size: 14px;
        }
        .single-option-active {
          background: #e9eff4;
          border-radius: 8px;
          color: #252d3d;
        }
      }
      .static-config {
        border-radius: 8px;
        border: 1px solid #e3e8ee;
        margin-top: 12px;
        .tools-line {
          height: 40px;
          line-height: 40px;
          border-bottom: 1px solid #e3e8ee;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 12px;
          .ope-icon {
            cursor: pointer;
            font-weight: bold;
            color: #5d687c;
          }
          .ope-icon + .ope-icon {
            margin-left: 8px;
          }
          .el-icon-plus {
            color: #2a61ff;
          }
          .el-icon-delete {
            margin-left: 16px !important;
          }
        }
        .list-container {
          padding: 16px;
          .static-line {
            height: 32px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .selected-span {
              cursor: pointer;
              display: inline-flex;
              width: 14px;
              height: 14px;
              border-radius: 50%;
              border: 1px solid #e4e7eb;
              align-items: center;
              justify-content: center;
              .inner {
                display: inline-block;
                height: 8px;
                width: 8px;
                border-radius: 50%;
                background: #ffffff;
              }
              .inner-active {
                background: #2a61ff;
              }
            }
            .selected-span-active {
              border: 1px solid #2a61ff;
            }
            .value-input {
              width: 60px;
            }
            .label-input {
              width: 96px;
            }
          }
        }
      }
      .inner-config {
        border-radius: 8px;
        border: 1px solid #e3e8ee;
        margin-top: 12px;
        height: 152px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        padding: 0 10px;
        .single-inner {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .inner-label {
            display: inline-block;
            width: 76px;
            height: 32px;
            line-height: 32px;
            background: #f7f8fa;
            border-radius: 4px;
            text-align: center;
          }
          .inner-input {
            width: 126px;
          }
        }
      }
      .classify-config {
        margin-top: 12px;
      }
      .debugger-span {
        position: absolute;
        right: 0;
        top: -35px;
        font-weight: 400;
        font-size: 14px;
        color: #2a61ff;
        cursor: pointer;
      }
    }
  }
  .single-form-require,
  .single-form-multiple,
  .single-form-filterable {
    padding-bottom: 0 !important;
  }
}
</style>