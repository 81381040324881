<template>
  <div class="debugger-dialog-component">
    <el-dialog
      :visible.sync="dataset.show"
      :before-close="dialogCloseHandler"
      width="1000px"
      title="调试效果"
      :destroy-on-close="true"
      :close-on-click-modal="false"
    >
      <div class="debugger-result">
        <div class="result-container">
          <div
            :class="['result-label', required ? 'result-label-required' : '']"
          >
            {{ label }}
          </div>
          <div class="result-selector">
            <el-select
              ref="selectorIns"
              style="width: 100%"
              v-model="value"
              :placeholder="placeHolder"
              clearable
              :multiple="multiple"
              :filterable="filterable"
            >
              <el-option
                v-for="(item, index) in optionList"
                :value="item.value"
                :label="item.label"
                :key="index"
              ></el-option>
            </el-select>
          </div>
        </div>
      </div>
      <div class="json-container">
        <div class="label-line">json代码</div>
        <ace-editor
          v-model="jsonResource"
          @init="editorInit"
          lang="json"
          theme="chrome"
          style="border: 1px solid #ebecec; margin-top: 15px"
          height="340px"
          width="100%"
        >
        </ace-editor>
      </div>
      <div class="button-line">
        <r-button type="cancel" @click="dialogCloseHandler">关闭</r-button>
        <r-button @click="debuggerHandler" :loading="debugLoading"
          >调试</r-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import request from "@/utils/request";
import AceEditor from "vue2-ace-editor";

export default {
  name: "debugger-dialog-component",
  components: { AceEditor },
  props: {
    dataset: {
      type: Object,
      default() {
        return {
          show: false,
        };
      },
    },
  },
  data() {
    return {
      jsonResource: null,
      debugLoading: false,
      required: false,
      multiple: false,
      filterable: false,
      label: null,
      value: null,
      placeHolder: null,
      clearable: false,
      optionList: [],
      debuggerForm: {
        serviceUrl: null,
        labelKey: null,
        valueKey: null,
      },
    };
  },
  watch: {
    dataset: {
      handler(data) {
        this.label = this.labelRender(data.datas, "label", "未命名");
        this.placeHolder = this.labelRender(
          data.datas,
          "placeHolder",
          "请输入"
        );
        this.clearable = this.labelRender(data.datas, "clearable", false);
        this.multiple = this.labelRender(data.datas, "multiple", false);
        this.value = this.labelRender(data.datas, "default", "");
        if (this.multiple) {
          this.value = this.value.split(",");
        }
        this.required = this.labelRender(data.datas, "require", false);
        this.filterable = this.labelRender(data.datas, "filterable", false);
      },
      deep: true,
    },
  },
  methods: {
    jsonParseHandler(content) {
      try {
        return JSON.stringify(content, null, "\t");
      } catch (error) {
        return "";
      }
    },
    editorInit: function (thingEditor) {
      require("brace/ext/language_tools"); //language extension prerequsite...
      require("brace/mode/json");
      require("brace/theme/chrome");
      thingEditor.setShowPrintMargin(false);
      thingEditor.setReadOnly(true);
    },
    async debuggerHandler() {
      const { serviceUrl, labelKey, valueKey } = this.dataset.datas;
      if (!serviceUrl || !labelKey || !valueKey) {
        this.$message.warning("请填写服务地址、显示内容、值后再调试");
        return;
      }
      this.debugLoading = true;
      request({
        url: serviceUrl,
        method: "get",
      })
        .then((res) => {
          try {
            this.jsonResource = this.jsonParseHandler(res);
            this.optionList = res.map((item) => {
              return {
                value: item[valueKey],
                label: item[labelKey],
              };
            });
            this.$message.success("结果解析成功！");
            // this.$nextTick(() => {
            //   this.$refs.selectorIns.toggleMenu();
            // });
          } catch (error) {
            console.error("内容解析失败:", error);
          }
        })
        .finally(() => {
          this.debugLoading = false;
        });
    },
    dialogCloseHandler() {
      this.$emit("close");
    },
    labelRender(datas, prop, defaultValue) {
      const { propsList } = datas;
      if (propsList && propsList.length) {
        for (let key of propsList) {
          if (key.key === prop) {
            return key.value || defaultValue;
          }
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.debugger-dialog-component {
  .debugger-result {
    .result-container {
      .result-label {
        font-weight: 600;
        font-size: 14px;
        color: #252d3d;
        margin-bottom: 12px;
      }
      .result-label-required {
        &::before {
          content: "*";
          color: #f56c6c;
        }
      }
    }
  }
  .json-container {
    margin: 28px 0 12px;
    .label-line {
      font-weight: 600;
      font-size: 14px;
      color: #252d3d;
    }
  }
  .button-line {
    text-align: right;
    margin: 40px 0;
  }
}
</style>