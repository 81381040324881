<template>
  <div class="json-view-dialog">
    <el-dialog
      width="900px"
      title="查看json代码"
      :before-close="dialogClose"
      :visible.sync="dataset.show"
    >
      <ace-editor
        v-model="dataset.json"
        @init="editorInit"
        lang="json"
        theme="chrome"
        style="border: 1px solid #ebecec"
        height="562px"
      ></ace-editor>
      <div class="button-line">
        <r-button plain @click="dialogClose">关闭</r-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import AceEditor from "vue2-ace-editor";

export default {
  name: "json-view-dialog",
  components: { AceEditor },
  props: {
    dataset: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  methods: {
    dialogClose() {
      this.$emit("close");
    },
    editorInit: function (thingEditor) {
      require("brace/ext/language_tools"); //language extension prerequsite...
      require("brace/mode/json");
      require("brace/mode/javascript"); //language
      require("brace/theme/chrome");
      thingEditor.setShowPrintMargin(false);
      thingEditor.setReadOnly(true);
    },
  },
};
</script>

<style lang="less" scoped>
.json-view-dialog {
  .button-line {
    text-align: right;
    margin: 20px 0 10px;
  }
}
</style>