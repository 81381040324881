<template>
  <div class="single-form-item">
    <div
      :class="[
        'item-label',
        labelRender(dataset, 'require', false) && 'item-label-required',
      ]"
    >
      {{ label }}
    </div>
    <div class="detail-line">
      <template v-if="dataset.key === 'input'">
        <el-input
          :readonly="readOnly"
          :placeholder="placeHolder"
          :maxlength="maxlength"
          v-model="value"
        ></el-input>
      </template>
      <template v-else-if="dataset.key === 'textarea'">
        <el-input
          :readonly="readOnly"
          type="textarea"
          resize="none"
          :rows="3"
          :placeholder="placeHolder"
          :maxlength="maxlength"
          v-model="value"
        ></el-input>
      </template>
      <template v-else-if="dataset.key === 'selector'">
        <!-- el-select在只读状态下点击事件没办法修改，点击到组件无法激活当前卡片，所以用input实现 -->
        <el-input
          :suffix-icon="
            clearable ? 'el-icon-circle-close' : 'el-icon-arrow-down'
          "
          :readonly="readOnly"
          :placeholder="placeHolder"
        >
        </el-input>
      </template>
      <template v-else-if="dataset.key === 'companySelector'">
        <!-- el-select在只读状态下点击事件没办法修改，点击到组件无法激活当前卡片，所以用input实现 -->
        <el-input
          :suffix-icon="
            clearable ? 'el-icon-circle-close' : 'el-icon-arrow-down'
          "
          :readonly="readOnly"
          :placeholder="placeHolder"
        >
        </el-input>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "single-form-item",
  props: {
    dataset: {
      type: Object,
      default() {
        return {};
      },
    },
    readOnly: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  data() {
    return {
      value: null,
      placeHolder: "请输入",
      label: "未命名",
      maxlength: 20,
      clearable: false,
    };
  },
  watch: {
    dataset: {
      handler(datas) {
        this.label = this.labelRender(datas, "label", "未命名");
        this.value = this.labelRender(datas, "default", "");
        this.placeHolder = this.labelRender(datas, "placeHolder", "请输入");
        this.maxlength = this.labelRender(datas, "maxLength", 20);
        this.clearable = this.labelRender(datas, "clearable", false);
        this.optionList = [];
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    labelRender(datas, prop, defaultValue) {
      const { propsList } = datas;
      if (propsList && propsList.length) {
        for (let key of propsList) {
          if (key.key === prop) {
            return key.value || defaultValue;
          }
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.single-form-item {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
  .item-label {
    font-weight: 600;
    font-size: 14px;
    color: #252d3d;
    cursor: default;
  }
  .item-label-required {
    &::before {
      content: "*";
      color: #f56c6c;
    }
  }
  ::v-deep .el-input__inner {
    cursor: default;
  }
  ::v-deep .el-textarea__inner {
    cursor: default;
  }
  ::v-deep .el-input.is-disabled .el-input__inner {
    background: #fff;
    cursor: default;
  }
}
</style>