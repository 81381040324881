var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "single-form-item" }, [
    _c(
      "div",
      {
        class: [
          "item-label",
          _vm.labelRender(_vm.dataset, "require", false) &&
            "item-label-required",
        ],
      },
      [_vm._v("\n    " + _vm._s(_vm.label) + "\n  ")]
    ),
    _c(
      "div",
      { staticClass: "detail-line" },
      [
        _vm.dataset.key === "input"
          ? [
              _c("el-input", {
                attrs: {
                  readonly: _vm.readOnly,
                  placeholder: _vm.placeHolder,
                  maxlength: _vm.maxlength,
                },
                model: {
                  value: _vm.value,
                  callback: function ($$v) {
                    _vm.value = $$v
                  },
                  expression: "value",
                },
              }),
            ]
          : _vm.dataset.key === "textarea"
          ? [
              _c("el-input", {
                attrs: {
                  readonly: _vm.readOnly,
                  type: "textarea",
                  resize: "none",
                  rows: 3,
                  placeholder: _vm.placeHolder,
                  maxlength: _vm.maxlength,
                },
                model: {
                  value: _vm.value,
                  callback: function ($$v) {
                    _vm.value = $$v
                  },
                  expression: "value",
                },
              }),
            ]
          : _vm.dataset.key === "selector"
          ? [
              _c("el-input", {
                attrs: {
                  "suffix-icon": _vm.clearable
                    ? "el-icon-circle-close"
                    : "el-icon-arrow-down",
                  readonly: _vm.readOnly,
                  placeholder: _vm.placeHolder,
                },
              }),
            ]
          : _vm.dataset.key === "companySelector"
          ? [
              _c("el-input", {
                attrs: {
                  "suffix-icon": _vm.clearable
                    ? "el-icon-circle-close"
                    : "el-icon-arrow-down",
                  readonly: _vm.readOnly,
                  placeholder: _vm.placeHolder,
                },
              }),
            ]
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }