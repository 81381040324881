var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "component-props-setting" }, [
    _vm.dataset.propsList && _vm.dataset.propsList.length
      ? _c(
          "div",
          { staticClass: "props-container" },
          [
            _c(
              "el-form",
              {
                ref: "propsForm",
                attrs: {
                  model: _vm.propsForm,
                  rules: _vm.formRules,
                  "label-width": "120px",
                  "label-position": "top",
                },
              },
              _vm._l(_vm.dataset.propsList, function (item) {
                return _c("SinglePropsItem", {
                  key: item.key,
                  attrs: {
                    dataset: item,
                    itemType: _vm.dataset.type,
                    propsList: _vm.dataset.propsList,
                  },
                  on: {
                    update: _vm.updateHandler,
                    getItem: _vm.getItemHandler,
                  },
                })
              }),
              1
            ),
          ],
          1
        )
      : _c("div", { staticClass: "default-container" }, [
          _c(
            "div",
            { staticClass: "info-list" },
            _vm._l(_vm.dataset.productInfo, function (item) {
              return _c(
                "div",
                { key: item.infoKey, staticClass: "info-item" },
                [
                  _c("div", { staticClass: "label-line" }, [
                    _vm._v("\n          " + _vm._s(item.label) + "\n        "),
                  ]),
                  _c("div", { staticClass: "value-line" }, [
                    _vm._v("\n          " + _vm._s(item.value) + "\n        "),
                  ]),
                ]
              )
            }),
            0
          ),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }