var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "json-view-dialog" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            width: "900px",
            title: "查看json代码",
            "before-close": _vm.dialogClose,
            visible: _vm.dataset.show,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.dataset, "show", $event)
            },
          },
        },
        [
          _c("ace-editor", {
            staticStyle: { border: "1px solid #ebecec" },
            attrs: { lang: "json", theme: "chrome", height: "562px" },
            on: { init: _vm.editorInit },
            model: {
              value: _vm.dataset.json,
              callback: function ($$v) {
                _vm.$set(_vm.dataset, "json", $$v)
              },
              expression: "dataset.json",
            },
          }),
          _c(
            "div",
            { staticClass: "button-line" },
            [
              _c(
                "r-button",
                { attrs: { plain: "" }, on: { click: _vm.dialogClose } },
                [_vm._v("关闭")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }